import { Container, Flex, useDisclosure } from '@chakra-ui/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { useIntl } from 'react-intl'

import { usePreferences } from 'lib/store/preferences'
import { ClinicSelect } from 'modules/clinic'

import { Header } from './Header'

export const Layout: React.FC<{
  layout?: 'normal' | 'centered' | 'none'
  hideMenu?: boolean
  subMenu?: React.ReactNode
}> = ({ layout = 'normal', children, hideMenu, subMenu }) => {
  const { pathname } = useRouter()
  const { formatMessage } = useIntl()

  const clinicModal = useDisclosure()
  const clinic = usePreferences((state) => state.clinic)
  const modalShouldBlockPage = !['/clinics'].includes(pathname)
  const shouldShowModal =
    (!clinic && modalShouldBlockPage) || clinicModal.isOpen

  return (
    <>
      <Head>
        <title>{formatMessage({ id: 'global.pageTitle' })}</title>
      </Head>
      <ClinicSelect isOpen={shouldShowModal} onClose={clinicModal.onClose} />
      <Flex key={clinic?.id} flexDir="column" h="100%">
        <Header
          hideMenu={hideMenu}
          onClinicSelectionOpen={clinicModal.onOpen}
        />
        {subMenu || null}
        {layout === 'normal' && (
          <Container flex="1" py="4">
            {children}
          </Container>
        )}
        {layout === 'centered' && (
          <Flex alignItems="center" justifyContent="center" flex="1" p="4">
            {children}
          </Flex>
        )}
        {layout === 'none' && <>{children}</>}
      </Flex>
    </>
  )
}
