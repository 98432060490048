import {
  Flex,
  Container,
  Stack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Skeleton,
  Box,
  IconButton,
  useDisclosure,
  Collapse,
  Divider,
  HStack,
  Text,
} from '@chakra-ui/react'
import { useRouter } from 'next/dist/client/router'
import React from 'react'
import {
  RiArrowDownSLine,
  RiArrowLeftRightLine,
  RiCalendarEventLine,
  RiCloseLine,
  RiLoginBoxLine,
  RiLogoutBoxLine,
  RiMenuLine,
  RiSettings4Line,
  RiSurveyLine,
} from 'react-icons/ri'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

import { useGetHeaderClinicsQuery } from 'lib/graphql/generated/hooks'
import { usePreferences } from 'lib/store/preferences'
import { useAuth } from 'modules/auth'
import { Logo } from 'modules/shared'

import { NavLink } from './NavLink'

const _GET_CLINICS = /* GraphQL */ `
  query getHeaderClinics {
    me {
      clinicUsers(hasRole: true) {
        clinic {
          id
        }
      }
    }
  }
`

const useLogout = (): { logout: () => void } => {
  const { logout } = useAuth()
  const client = useQueryClient()
  const router = useRouter()

  const logoutAction = () => {
    logout()
    client.clear()
    router.push('/login')
  }

  return { logout: logoutAction }
}

export const Header = ({
  hideMenu,
  onClinicSelectionOpen,
}: {
  hideMenu?: boolean
  onClinicSelectionOpen: () => void
}): JSX.Element => {
  const { formatMessage } = useIntl()
  const { isLoading: isLoadingAuth, isLoggedIn } = useAuth()
  const { data, isLoading: isLoadingClinics } = useGetHeaderClinicsQuery(
    undefined,
    {
      enabled: isLoggedIn,
    }
  )

  const isLoading = isLoadingAuth || isLoadingClinics

  return (
    <Flex
      alignItems="center"
      bg="white"
      shadow="sm"
      position="relative"
      py="2"
      h="14"
      flexShrink={0}
    >
      <Container>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" w="60">
            <Logo />
          </Flex>
          {!hideMenu && (
            <>
              {isLoading && <Skeleton w="32" h="6" />}
              {!isLoading && isLoggedIn && (
                <>
                  <DesktopMenu
                    onClinicSelectionOpen={onClinicSelectionOpen}
                    userHasClinics={(data?.me.clinicUsers.length ?? []) > 1}
                  />
                  <MobileMenu
                    onClinicSelectionOpen={onClinicSelectionOpen}
                    userHasClinics={(data?.me.clinicUsers.length ?? []) > 1}
                  />
                </>
              )}
              {!isLoading && !isLoggedIn && (
                <NavLink
                  label={formatMessage({ id: 'menu.login' })}
                  href="/login"
                  icon={<RiLoginBoxLine />}
                />
              )}
            </>
          )}
        </Flex>
      </Container>
    </Flex>
  )
}

const MenuItems = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <NavLink
        label={formatMessage({ id: 'menu.appointments' })}
        href="/appointments"
        icon={<RiSurveyLine />}
      />
      <NavLink
        label={formatMessage({ id: 'menu.calendar' })}
        href="/calendar"
        icon={<RiCalendarEventLine />}
      />
      <NavLink
        label={formatMessage({ id: 'menu.settings' })}
        href="/settings"
        icon={<RiSettings4Line />}
      />
    </>
  )
}

const DesktopMenu = ({
  onClinicSelectionOpen,
  userHasClinics,
}: {
  onClinicSelectionOpen: () => void
  userHasClinics: boolean
}) => {
  const { formatMessage } = useIntl()
  const { logout } = useLogout()
  const clinic = usePreferences((state) => state.clinic)

  return (
    <>
      <HStack spacing="6" display={{ base: 'none', lg: 'initial' }}>
        <MenuItems />
      </HStack>
      <Box display={{ base: 'none', lg: 'initial' }} w="60" textAlign="right">
        <Menu placement="bottom-end" autoSelect={false}>
          <MenuButton
            as={Button}
            rightIcon={<RiArrowDownSLine />}
            variant="nav"
          >
            <Text isTruncated maxWidth="40">
              {clinic?.displayName || formatMessage({ id: 'menu.account' })}
            </Text>
          </MenuButton>
          <MenuList>
            {userHasClinics && (
              <MenuItem onClick={onClinicSelectionOpen}>
                <HStack alignItems="center">
                  <RiArrowLeftRightLine />
                  <span>{formatMessage({ id: 'menu.switchClinic' })}</span>
                </HStack>
              </MenuItem>
            )}
            <MenuItem color="red.500" onClick={logout}>
              <HStack alignItems="center">
                <RiLogoutBoxLine />
                <span>{formatMessage({ id: 'menu.logout' })}</span>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </>
  )
}

const MobileMenu = ({
  onClinicSelectionOpen,
  userHasClinics,
}: {
  onClinicSelectionOpen: () => void
  userHasClinics: boolean
}) => {
  const { formatMessage } = useIntl()
  const { isOpen, onToggle } = useDisclosure()
  const { logout } = useLogout()
  const clinic = usePreferences((state) => state.clinic)

  return (
    <>
      <Flex alignItems="center" display={{ base: 'initial', lg: 'none' }}>
        <IconButton
          icon={isOpen ? <RiCloseLine /> : <RiMenuLine />}
          aria-label={formatMessage({ id: 'global.menu' })}
          variant="ghost"
          borderRadius="base"
          onClick={onToggle}
        />
      </Flex>
      <Box
        position="absolute"
        zIndex="dropdown"
        top="100%"
        left="0"
        right="0"
        bg="white"
        shadow={isOpen ? 'sm' : 'none'}
        display={{ base: 'initial', lg: 'none' }}
      >
        <Collapse in={isOpen} animateOpacity>
          <Divider />
          <Container>
            <Stack alignItems="flex-start" py="2">
              <MenuItems />
            </Stack>
          </Container>
          <Divider />
          <Container>
            <Stack alignItems="flex-start" py="2">
              {userHasClinics && (
                <Button
                  variant="nav"
                  leftIcon={<RiArrowLeftRightLine />}
                  onClick={onClinicSelectionOpen}
                >
                  <Box textAlign="left">
                    <Text>{formatMessage({ id: 'menu.switchClinic' })}</Text>
                    <Text fontSize="sm" color="gray.400">
                      {clinic?.displayName || '–'}
                    </Text>
                  </Box>
                </Button>
              )}
              <Button
                color="red.500"
                variant="nav"
                leftIcon={<RiLogoutBoxLine />}
                onClick={logout}
              >
                {formatMessage({ id: 'menu.logout' })}
              </Button>
            </Stack>
          </Container>
          <Divider />
        </Collapse>
      </Box>
    </>
  )
}
