import {
  Modal,
  ModalOverlay,
  ModalContent,
  Heading,
  Text,
  Stack,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { RiHomeSmileLine } from 'react-icons/ri'
import { useIntl } from 'react-intl'

import { useGetUserClinicsQuery } from 'lib/graphql/generated/hooks'
import { usePreferences } from 'lib/store/preferences'
import { useAuth } from 'modules/auth'

const _GET_CLINICS = /* GraphQL */ `
  query getUserClinics {
    me {
      clinicUsers(hasRole: true) {
        role
        clinic {
          id
          displayName
          timezone
          defaultPatientLanguage
          defaultPatientCountry
        }
      }
    }
  }
`

export const ClinicSelect = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}): JSX.Element | null => {
  const { formatMessage } = useIntl()

  const clinic = usePreferences((state) => state.clinic)
  const selectClinic = usePreferences((state) => state.selectClinic)

  const { isLoading: authLoading, isLoggedIn } = useAuth()

  const { data, isLoading: clinicsLoading, error } = useGetUserClinicsQuery(
    {},
    {
      enabled: isLoggedIn && !authLoading,
      staleTime: 60 * 60 * 1000,
    }
  )

  const clinics = useMemo(
    () =>
      data?.me.clinicUsers.map((user) => ({
        ...user.clinic,
        role: user.role,
      })) || [],
    [data?.me.clinicUsers]
  )

  useEffect(
    function autoSelect() {
      if (clinics.length === 1) {
        selectClinic(clinics[0])
      }
    },
    [selectClinic, clinics]
  )

  if (authLoading || clinicsLoading) {
    return null
  }

  return (
    <>
      {error && (
        <Modal
          isOpen
          isCentered
          onClose={() => undefined}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent p="4" color="red.500">
            <Heading size="md" mb="1">
              {formatMessage({ id: 'global.error' })}
            </Heading>
            <Text>
              {formatMessage({ id: 'global.essentialDataFail' })}{' '}
              {formatMessage({ id: 'global.refreshTryAgainOrContactSupport' })}
            </Text>
          </ModalContent>
        </Modal>
      )}
      {isLoggedIn && isOpen && (
        <Modal
          isOpen
          isCentered
          onClose={() => onClose()}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent p="4" m="4">
            {clinic && <ModalCloseButton />}
            <Heading size="md" mb="1">
              {formatMessage({ id: 'clinicSelect.title' })}
            </Heading>
            <Text mb="4" color="gray.700">
              {formatMessage({ id: 'clinicSelect.text' })}
            </Text>
            <Stack>
              {clinics.length === 0 && (
                <Text color="red.500">
                  {formatMessage({ id: 'clinicSelect.noClinics' })}
                </Text>
              )}
              {clinics.map((clinic) => {
                return (
                  <Button
                    key={clinic.id}
                    leftIcon={<RiHomeSmileLine />}
                    onClick={() => {
                      selectClinic(clinic)
                      onClose()
                    }}
                    size="lg"
                  >
                    <Text isTruncated maxWidth="100%">
                      {clinic.displayName}
                    </Text>
                  </Button>
                )
              })}
            </Stack>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
