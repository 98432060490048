import { Divider, Flex, HStack } from '@chakra-ui/react'
import React from 'react'
import {
  RiEmpathizeFill,
  RiHealthBookFill,
  RiNurseFill,
  RiTeamFill,
} from 'react-icons/ri'
import { useIntl } from 'react-intl'

import { NavLink } from './NavLink'

export const SettingsMenu = (): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Divider borderBottomColor="gray.50" />
      <Flex
        alignItems="center"
        justifyContent={['flex-start', null, 'center']}
        bg="white"
        shadow="sm"
        py="2"
        h="10"
      >
        <HStack spacing={['2', '4', '6']} overflowX="auto" px="2">
          <NavLink
            href="/settings/users"
            label={formatMessage({ id: 'menu.settings.users' })}
            icon={<RiTeamFill />}
          />
          <NavLink
            href="/settings/staff"
            label={formatMessage({ id: 'menu.settings.staff' })}
            icon={<RiNurseFill />}
          />
          <NavLink
            href="/settings/patients"
            label={formatMessage({ id: 'menu.settings.patients' })}
            icon={<RiEmpathizeFill />}
          />
          <NavLink
            href="/settings/appointment-types"
            label={formatMessage({ id: 'menu.settings.types' })}
            icon={<RiHealthBookFill />}
          />
        </HStack>
      </Flex>
    </>
  )
}
