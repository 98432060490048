import { Button, ButtonProps } from '@chakra-ui/react'
import { useRouter } from 'next/dist/client/router'
import NextLink from 'next/link'
import React from 'react'

export const NavLink = ({
  href,
  label,
  icon,
}: {
  href: string
  label: string
  icon: ButtonProps['leftIcon']
}): JSX.Element => {
  const router = useRouter()

  return router.pathname === href ? (
    <Button
      isDisabled
      leftIcon={icon}
      variant="nav"
      pointerEvents="none"
      flexShrink={0}
    >
      {label}
    </Button>
  ) : (
    <NextLink href={href} passHref>
      <Button as="a" leftIcon={icon} variant="nav" flexShrink={0}>
        {label}
      </Button>
    </NextLink>
  )
}
